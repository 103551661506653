import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Block.tsx";
import { Button, Grid, Box, Typography } from '@material-ui/core';
import Demo from '@/components/DemoForm';
import heroImage from '@/assets/Phone_mock_credfin.png';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Grid container justifyContent="space-around" alignItems="center" mdxType="Grid">
  <Grid item maxWidth={600} flexGrow={1} mdxType="Grid">
  <Typography variant="h2" style={{
          fontSize: '2.7em',
          fontWeight: 800,
          textAlign: 'left'
        }} gutterBottom mdxType="Typography">
    We help you understand your customers through bank transaction insights
  </Typography>
        <p>{`TaleFin provides Fintechs & developers with the tools to obtain customer consented transaction data & insights.
Securely connect your apps to financial institutions using one simple API.`}</p>
  <Grid item paddingBottom={4} mdxType="Grid">
    <Grid container spacing={2} paddingY={4} mdxType="Grid">
      <Grid item mdxType="Grid">
        <Button size="large" href="/about-us/contact" mdxType="Button">Let's Talk</Button>
      </Grid>
      <Grid item mdxType="Grid">
        <Demo mdxType="Demo" />
      </Grid>
    </Grid>
  </Grid>
  </Grid>
  <Grid item flexGrow={0} mdxType="Grid">
    <img src={heroImage} style={{
          width: 600,
          height: 600
        }} alt="iframe demo" />
  </Grid>
    </Grid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      