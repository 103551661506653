import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Block.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We provide companies and developers with all the tools needed to quickly and securely acquire consented bank statement data. Used by financial service businesses and emerging fintechs, we allow them to simplify application processes and develop fantastic new financial products. We help you deliver a great customer experience for consumers.`}</p>
    <br />
    <p>{`Companies can integrate our iFrame into their customer application journey by combining our content anywhere within your page. The iFrame consists of customisable features and colours and can be set up in minutes. Alternatively, you can access our API by contacting our Customer Success team, who will help you get access set up.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      