import React from 'react';
import {MDXProvider} from '@mdx-js/react';
import Typography from '@material-ui/core/Typography';

import Preamble from './preamble.mdx';
import LinkTiles from '../../components/LinkTiles';

export const Solutions = () => {
  return (
    <>
      <MDXProvider
        components={{
          h1: (props) => (
            <Typography
              variant="h1"
              textAlign="center"
              gutterBottom
              {...props}
            />
          ),
        }}
      >
        <Preamble />
      </MDXProvider>
      <LinkTiles parent="/solutions" />
    </>
  );
};

export default Solutions;
