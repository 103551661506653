import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Block.tsx";
import CentreAlign from '../components/CentreAlign';
import Container from '@material-ui/core/Container';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <CentreAlign mdxType="CentreAlign">
      <Container maxWidth="md" mdxType="Container">
        <h4 {...{
          "id": "our-solutions-give-developers-the-ability-to-build-the-future-of-financial-technology",
          "style": {
            "position": "relative"
          }
        }}>{`Our solutions give developers the ability to build the future of Financial Technology.`}<a parentName="h4" {...{
            "href": "#our-solutions-give-developers-the-ability-to-build-the-future-of-financial-technology",
            "aria-label": "our solutions give developers the ability to build the future of financial technology permalink",
            "className": "anchor after"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a></h4>
        <br />
        <p>{`This gives consumers the power to share their data with companies, allowing a deep understanding of a customer's affordability, reliability & social behaviour indicators from their spending habits. This supports lending responsibility compliance and the consumer’s ability to repay commitments.`}</p>
      </Container>
    </CentreAlign>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      