import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Block.tsx";
import ThumbsUpIcon from '@/assets/industry-leading-consumer-experience.webp';
import IntegrationIcon from '@/assets/easy-integration.webp';
import RocketIcon from '@/assets/speed-performance.webp';
import AnalysisIcon from '@/assets/reanalyse-data.webp';
import HandshakeIcon from '@/assets/customer-success-partnership.webp';
import StonksIcon from '@/assets/expert-financial-insights.webp';
import Grid from '@material-ui/core/Grid';
import BenefitBlock from './BenefitBlock';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Grid container spacing={4} mdxType="Grid">
      <BenefitBlock title="Better Consumer Experience" icon={ThumbsUpIcon} mdxType="BenefitBlock">
        <p>{`Our Financial Insights are delivered while the customer is still progressing through the application journey, allowing dynamic decisioning in real-time.
Reducing application time is critical to increasing conversion rate, retaining revenue, and keeping your customer happy.`}</p>
      </BenefitBlock>
      <BenefitBlock title="Easy Integration" icon={IntegrationIcon} mdxType="BenefitBlock">
        <p>{`Get started with a 2-line integration using our ready to go iFrame, or the API for a deeper solution.
We reduce the pain of migrating from other data aggregators by providing all your field mapping needs and recommendations.
TaleFin provides Software Engineers to expedite your integration.`}</p>
      </BenefitBlock>
      <BenefitBlock title="Speed & Performance" icon={RocketIcon} mdxType="BenefitBlock">
        <p>{`99.99% up-time with Financial Insight reports delivered in as little as 15 seconds.
Over 170+ bank connections with even more coming!
This ensures that on average 99% of all Australian Bank Accounts are retrievable.`}</p>
      </BenefitBlock>
      <BenefitBlock title="Reanalyse Data for ML Models" icon={AnalysisIcon} mdxType="BenefitBlock">
        <p>{`TaleFin can reanalyse data from any source you provide. Send us your data in JSON, XML or any other structured format, and our team can process it.
We will analyse the data to allow you to compare and match while building baseline models for ML.`}</p>
      </BenefitBlock>
      <BenefitBlock title="Customer Partnership" icon={HandshakeIcon} mdxType="BenefitBlock">
        <p>{`We partner with you to make it easy to implement and maximise the potential of your investment. We help optimise
customer journeys and develop custom metics specific to your individual needs. We're here to make sure everything goes smoothly.`}</p>
      </BenefitBlock>
      <BenefitBlock title="Expert Financial Insights" icon={StonksIcon} mdxType="BenefitBlock">
        <p>{`Financial Insights across income, expenditures, loans, credit cards, risk categories, crypto currency, and much more.
Bespoke decision metrics are also available for all of our clients, enquire today about a custom solution for you.`}</p>
      </BenefitBlock>
    </Grid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      