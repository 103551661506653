import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Block.tsx";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FirewallIcon from '@/assets/security/restricted-network-access.webp';
import LockIcon from '@/assets/security/data-encryption-methods.webp';
import ServerCertIcon from '@/assets/security/protected-environment.webp';
import PasswordIcon from '@/assets/security/mfa-processes.webp';
import EyeSeeYouIcon from '@/assets/security/threat-monitoring-in-real-time.webp';
import DocCogIcon from '@/assets/security/secure-developer-standards.webp';
import SecurityBlock from './SecurityBlock';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Box textAlign="center" display="flex" flexDirection="column" alignItems="center" paddingBottom={6} mdxType="Box">
      <h1 {...{
        "id": "talefin-security",
        "style": {
          "position": "relative"
        }
      }}>{`TaleFin Security`}<a parentName="h1" {...{
          "href": "#talefin-security",
          "aria-label": "talefin security permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h1>
      <br />
      <p>{`Our security approach focuses on security governance, risk management and compliance. This plan includes encryption at rest and in transit, network security and server hardening from attacks, admin access control, system monitoring, logging and alerting, along with a host of other measures we have in place to keep TaleFin and our clients secure.`}</p>
    </Box>
    <Grid container spacing={4} mdxType="Grid">
      <SecurityBlock title="Protected Environment" icon={ServerCertIcon} mdxType="SecurityBlock">
        <p>{`Our physical infrastructure is hosted and managed in ISO 27001, SOC 1 & SOC 2, PCI Level 1, and Google Cloud Platform certified data centres.`}</p>
      </SecurityBlock>
      <SecurityBlock title="Restricted Network Access" icon={FirewallIcon} mdxType="SecurityBlock">
        <p>{`Firewalls and DMZs are utilised to restrict access to systems from external networks and between systems internally.`}</p>
      </SecurityBlock>
      <SecurityBlock title="Secure Developer Standards" icon={DocCogIcon} mdxType="SecurityBlock">
        <p>{`TaleFin follows industry-standard secure coding guidelines, examining vulnerabilities, risks, impacts, and the best countermeasures to keep your data safe.`}</p>
      </SecurityBlock>
      <SecurityBlock title="MFA Processes" icon={PasswordIcon} mdxType="SecurityBlock">
        <p>{`Multi-factor authentication and robust password administration are required for admin access to all systems.`}</p>
      </SecurityBlock>
      <SecurityBlock title="Data Encryption Methods" icon={LockIcon} mdxType="SecurityBlock">
        <p>{`We store data using 256-bit AES encryption and use an SSL/TLS secure tunnel to transfer data between the customer and our APIs.`}</p>
      </SecurityBlock>
      <SecurityBlock title="Threat Monitoring In Real-Time" icon={EyeSeeYouIcon} mdxType="SecurityBlock">
        <p>{`We conduct behavioural monitoring, vulnerability assessments, and intrusion detection to keep our systems safe and secure.`}</p>
      </SecurityBlock>
    </Grid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      