import React, {ComponentProps} from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import {styled} from '../theme';
import DemoForm from '../components/DemoForm';
import CentreAlign from '../components/CentreAlign';

const BlackTitle = styled.h1`
  color: black !important;
  font-weight: 800;
  margin-top: ${({theme}) => theme.spacing(6)};
`;

const PrimarySubtitle = styled((props: ComponentProps<typeof Typography>) => (
  <Typography variant="body2" {...props} />
))`
  margin-bottom: ${({theme}) => theme.spacing(4)};
  font-size: 20px;
`;

export const TryToday = () => (
  <CentreAlign>
    <BlackTitle>Try TaleFin Today!</BlackTitle>
    <PrimarySubtitle>
      Get instant access to Metrics, iFrame, API and Dashboard using only your
      email. 1 Click Access!
    </PrimarySubtitle>
    <Grid container spacing={2} paddingY={4} justifyContent="center">
      <Grid item>
        <Button href="/about-us/contact">Let&apos;s Talk</Button>
      </Grid>
      <Grid item>
        <DemoForm />
      </Grid>
    </Grid>
  </CentreAlign>
);

export default TryToday;
