import React, {HTMLAttributes} from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import {useSxLayout} from '@/hooks/useSxLayout';
import {styled} from '@/theme';
import HowItWorksIcon from '@/assets/who-are-we/api-iframes.webp';

import HowItWorksContent from './how-it-works.mdx';

const Root = styled(Container)`
  padding: ${({theme}) => theme.spacing(8)};
`;

const MaxSizeImage = styled.div<
  HTMLAttributes<HTMLDivElement> & {size: number}
>`
  & > * {
    width: ${({size}) => size}%;
    height: auto;
  }
`;

const Title = () => <Typography variant="h1">How It Works</Typography>;

export const HowItWorksLayout = () => {
  const {mobile, desktop} = useSxLayout();

  return (
    <Root>
      <Grid
        container
        justifyContent="space-evenly"
        alignItems="center"
        sx={{flexDirection: {xs: 'column', md: 'row'}}}
      >
        <Grid item xs={12} sx={{...mobile, order: {xs: 0, md: 1}}}>
          <Title />
        </Grid>

        <Grid item sm={12} md={5} sx={{order: {xs: 1, md: 0}}}>
          <Box display="flex" justifyContent="center" padding={2}>
          <MaxSizeImage size={100}>
              <img src={HowItWorksIcon}/>
            </MaxSizeImage>
          </Box>
        </Grid>

        <Grid item sm={12} md={7} sx={{order: 2}}>
          <Box sx={desktop}>
            <Title />
          </Box>
          <HowItWorksContent />
        </Grid>
      </Grid>
    </Root>
  );
};

export default HowItWorksLayout;
